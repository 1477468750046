








































































































import { Component, Vue } from "vue-property-decorator";
import AppAddressWidget from "@/components/widgets/AppAddressWidget.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppLineItem from "@/components/AppLineItem.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppOrderInstructionsWidget from "@/components/widgets/AppOrderInstructionsWidget.vue";
import AppOrderMessageWidget from "@/components/widgets/AppOrderMessageWidget.vue";
import AppStartOrderModal from "@/components/modals/AppStartOrderModal.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { currentUser } from "@/utils/authentication";
import cartHandler from "@/store/cart";
import AppCCWidget from "@/components/widgets/AppCCWidget.vue";

@Component({
  components: {
    AppCCWidget,
    AppAddressWidget,
    AppButton,
    AppLineItem,
    AppListGroup,
    AppListGroupItem,
    AppOrderInstructionsWidget,
    AppOrderMessageWidget,
    AppStartOrderModal,
    TheHeader,
    TheMain,
  },
})
export default class Cart extends Vue {
  order: pro.OrderDetail | null = null;
  showStartOrderModal = false;

  get lines(): pro.Line[] {
    if (!this.order || !this.order.lines) return [];
    return this.order.lines;
  }

  get consignee(): pro.Address | null {
    if (this.order && this.order.consignee) return this.order.consignee;
    return null;
  }

  get hasLines(): boolean {
    if (!this.order || !this.order.lines) return false;
    return this.order.lines.length > 0;
  }

  mounted() {
    this.order = cartHandler.cart ?? null;
    this.useDefaultAddress();
  }

  /**
   * Redirects to the Checkpoint view
   */
  checkout() {
    this.$router.push({ name: "ORDER_CHECKOUT" });
  }

  /**
   * Saves changes to a confirmation message
   */
  saveConfirmationMessage(message?: string): void {
    if (!this.order) return;
    cartHandler.updateMessage(message);
    this.order = cartHandler.cart ?? null;
  }

  /**
   * Saves changes special order instructions
   */
  saveInstructions(instructions?: string): void {
    if (!this.order) return;
    cartHandler.updateInstructions(instructions);
    this.order = cartHandler.cart ?? null;
  }

  /**
   * Save additional notification recipients
   */
  saveCC(cc?: string): void {
    if (!this.order) return;
    cartHandler.updateCC(cc);
    this.order = cartHandler.cart ?? null;
  }

  /**
   * Sets the order consignee as the User's default address, if no consignee is
   * set and one exists
   */
  useDefaultAddress(): void {
    if (this.order && this.order.consignee) return;
    const defaultAddress = currentUser().defaultAddress;
    if (defaultAddress && this.order) {
      this.order.consignee = defaultAddress;
      cartHandler.updateConsignee(defaultAddress);
    }
  }

  /**
   * Redirects to the Catalog's Series list view
   */
  keepShopping() {
    if (!this.order || !this.order.catalog) return;
    this.$router.push({
      name: "CATALOG",
      params: { catalogId: String(this.order.catalog.id) },
    });
  }

  /**
   * Updates an Order Line quantity
   */
  updateLineQty(line: pro.Line, qty: number): void {
    line.qty = qty;
    cartHandler.updateLineInCart(line);
  }

  /**
   * Removes a Line from an Order
   */
  removeLine(line: pro.Line): void {
    cartHandler.removeFromCart(line);
    this.order = cartHandler.cart ?? null;
  }
}
